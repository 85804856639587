import { Link } from "gatsby";
import React from "react";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import BackgroundIcon from "../images/Card bg logo cropped.svg";

const PriceCard = ({
	title,
	price,
	list,
	priceMinHeight,
	buttonHeight,
	priceMinHeightXl,
	buttonTitle = "Book a discovery call",
	buttonTarget = "_blank",
	buttonLink = "https://outlook.office.com/bookwithme/user/1386c77a23fa477c8c1f8232294da53c@cranfielditsolutions.co.uk/meetingtype/OHHZY3rg40-mG-U4B1-gjw2?anonymous&ep=mLinkFromTile",
}) => {
	return (
		<Col style={{ minHeight: "100%" }} lg={4}>
			<div
				style={{
					borderRadius: "4px",
					border: "1px solid white",
					overflow: "hidden",
					minHeight: "100%",
				}}
				className="position-relative p-4  bg-black w-100"
			>
				{" "}
				<div style={{ zIndex: 1 }} className="position-absolute start-0 w-100">
					<img alt="" className="mb-3 w-100 " src={BackgroundIcon} />
				</div>
				<div
					style={{ zIndex: 4 }}
					className="position-absolute start-0 text-center px-4 px-md-0 bottom-0 pb-4 w-100"
				>
					{/* <Button
						as={Link}
						to="/contact-us"
						variant="outline-primary"
						className="px-4 mt-lg-10 text-white  py-3 w-100 w-md-auto"
					>
						Book a sales call
					</Button> */}

					<a
						className="btn btn-primary px-5 mt-xl-5 mt-4 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto"
						href={buttonLink}
						target={buttonTarget}
						rel="noopener noreferrer"
					>
						{buttonTitle}
					</a>
				</div>
				<div className="">
					<Row>
						<Col className="text-center">
							<h3>{title}</h3>
						</Col>
					</Row>
					<Row>
						<Col className=" ps-4 ps-md-5 ps-lg-4 ps-xl-5">
							<div className="d-lg-none mb-3">{price}</div>
							<div
								className=" d-none d-lg-block position-relative d-xl-none"
								style={{ minHeight: priceMinHeight, zIndex: 4 }}
							>
								{price}
							</div>
							<div
								className="d-none d-xl-block position-relative"
								style={{ minHeight: priceMinHeightXl, zIndex: 4 }}
							>
								{price}
							</div>
							<div style={{ zIndex: 4 }} className="position-relative">
								{list}
							</div>
							<div style={{ height: buttonHeight }}></div>
						</Col>
					</Row>
				</div>
			</div>
		</Col>
	);
};

export default PriceCard;
